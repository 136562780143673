import React, { useState, useEffect, useRef } from "react";
import { FaDiceD20, FaUsers, FaCalendarAlt, FaUserCircle, FaBookOpen, FaStickyNote, FaCog, FaSignOutAlt, FaUserAlt } from "react-icons/fa";
import { GiBackpack, GiSpellBook, GiBank } from "react-icons/gi";
import Campanha from './campanha';
import Jogadores from './jogadores';
import Personagem from './personagem';
import Ficha from './ficha';
import Sessao from './sessao';
import ListaSessao from './lista_sessao';
import Calendario from './calendario';
import Notas from './notas';
import Banco from './banco';
import Inventario from './inventario';
import Perfil from './perfil';

const Site = () => {
  const [activeTab, setActiveTab] = useState("campanha");
  const [isMobile, setIsMobile] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const profileMenuRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
        setIsProfileMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const tabs = [
    { id: "campanha", label: "Campanhas", icon: FaDiceD20 },
    { id: "jogadores", label: "Jogadores", icon: FaUsers },
    { id: "personagem", label: "Personagens", icon: FaUserCircle },
    { id: "ficha", label: "Ficha", icon: GiSpellBook },
    { id: "sessao", label: "Sessões", icon: FaCalendarAlt },
    { id: "lista_sessao", label: "Lista de Sessões", icon: FaBookOpen },
    { id: "calendario", label: "Calendário", icon: FaBookOpen },
    { id: "notas", label: "Anotações", icon: FaStickyNote },
    { id: "banco", label: "Banco", icon: GiBank },
    { id: "inventario", label: "Inventário", icon: GiBackpack },
    { id: "perfil", label: "Perfil", icon: FaUserAlt },
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case "campanha":
        return <Campanha />;
      case "jogadores":
        return <Jogadores isMobile={isMobile} />;
      case "personagem":
        return <Personagem />;
      case "ficha":
        return <Ficha />;
      case "sessao":
        return <Sessao />;
      case "lista_sessao":
        return <ListaSessao isMobile={isMobile} />;
      case "calendario":
        return <Calendario />;
      case "notas":
        return <Notas />;
      case "banco":
        return <Banco isMobile={isMobile} />;
      case "inventario":
        return <Inventario isMobile={isMobile} />;
      case "perfil":
        return <Perfil />;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-[#36393f] text-white">
      <header className="bg-[#202225] p-4 flex justify-between items-center">
        <h1 className="text-2xl font-bold">Diário de Campanha</h1>
        <div className="relative" ref={profileMenuRef}>
          <button
            onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
            className="text-white hover:text-gray-300 focus:outline-none"
          >
            <FaUserCircle className="text-2xl" />
          </button>
          {isProfileMenuOpen && (
            <div className="absolute right-0 mt-2 w-48 bg-[#2f3136] rounded-md overflow-hidden shadow-xl z-10">
              <button
                onClick={() => {/* Handle profile click */}}
                className="block w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-[#40444b] flex items-center"
              >
                <FaUserCircle className="mr-2" /> Perfil
              </button>
              <button
                onClick={() => {/* Handle settings click */}}
                className="block w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-[#40444b] flex items-center"
              >
                <FaCog className="mr-2" /> Configurações
              </button>
              <button
                onClick={() => {/* Handle logout click */}}
                className="block w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-[#40444b] flex items-center"
              >
                <FaSignOutAlt className="mr-2" /> Sair
              </button>
            </div>
          )}
        </div>
      </header>
      <div className="flex">
        <nav className={`${isMobile ? "w-16" : "w-64"} bg-[#2f3136] min-h-screen p-4`}>
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`flex items-center w-full p-2 mb-2 rounded ${
                activeTab === tab.id ? "bg-[#7289da] text-white" : "hover:bg-[#40444b]"
              }`}
              onClick={() => setActiveTab(tab.id)}
            >
              <tab.icon className={`${isMobile ? "mx-auto" : "mr-2"}`} />
              {!isMobile && tab.label}
            </button>
          ))}
        </nav>
        <main className="flex-grow p-8">{renderTabContent()}</main>
      </div>
    </div>
  );
};

export default Site;