// src/hooks/useAuth.js
import { useEffect, useState } from 'react';
import { supabase } from '../utils/supabaseClient';

export const useAuth = () => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem('authUser');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  useEffect(() => {
    const getSession = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();
      if (error) {
        console.error('Erro ao obter sessão:', error.message);
        return;
      }
      if (session?.user) {
        setUser(session.user);
        localStorage.setItem('authUser', JSON.stringify(session.user));
      } else {
        setUser(null);
        localStorage.removeItem('authUser');
      }
    };

    getSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_, session) => {
      if (session?.user) {
        setUser(session.user);
        localStorage.setItem('authUser', JSON.stringify(session.user));
      } else {
        setUser(null);
        localStorage.removeItem('authUser');
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const updateUser = (newUser) => {
    setUser(newUser);
    if (newUser) {
      localStorage.setItem('authUser', JSON.stringify(newUser));
    } else {
      localStorage.removeItem('authUser');
    }
  };

  return { user, updateUser };
};