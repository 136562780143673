import React, { useState, useEffect } from "react";
import { FaHeartBroken, FaHeart, FaUserAlt, FaShieldAlt, FaMagic, FaCircle, FaSquare, FaStar, FaTrash, FaTimes, FaFireAlt, FaBolt, FaSnowflake } from "react-icons/fa";
/* import { GiCheckedShield, GiCherish } from "react-icons/gi"; */

const Ficha = () => {
  const [health, setHealth] = useState(50);
  const [maxHealth, setMaxHealth] = useState(100);
  const [xp, setXp] = useState(2500);
  const [level, setLevel] = useState(5);
  const [armorClass, setArmorClass] = useState(15);
  const [spellSlots, setSpellSlots] = useState({
    1: { name: "Skill 1", used: 2, total: 4, icon: FaCircle, color: "#FF5733", spellIcon: FaMagic },
    2: { name: "Skill 2", used: 1, total: 3, icon: FaSquare, color: "#33FF57", spellIcon: FaMagic },
    3: { name: "Skill 3", used: 0, total: 2, icon: FaStar, color: "#3357FF", spellIcon: FaMagic },
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [healthLogs, setHealthLogs] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isDamagePopupOpen, setIsDamagePopupOpen] = useState(false);
  const [isHealingPopupOpen, setIsHealingPopupOpen] = useState(false);
  const [damageAmount, setDamageAmount] = useState(1);
  const [healingAmount, setHealingAmount] = useState(1);

  const xpToNextLevel = 5000;

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSpellSlotClick = (level, index) => {
    setSpellSlots(prevSlots => ({
      ...prevSlots,
      [level]: {
        ...prevSlots[level],
        used: index + 1 > prevSlots[level].used ? index + 1 : index
      }
    }));
  };

  const handleHealthReduction = () => {
    setIsDamagePopupOpen(true);
  };

  const handleHealthIncrease = () => {
    setIsHealingPopupOpen(true);
  };

/*
  const handleHealthReduction = (event) => {
    event.preventDefault();
    let reduction = 1;
    if (event.shiftKey) reduction = 5;
    if (event.ctrlKey) reduction = 10;
    const newHealth = Math.max(0, health - reduction);
    setHealth(newHealth);
    addHealthLog("Dano", reduction);
  };

 const handleHealthIncrease = (event) => {
    event.preventDefault();
    let increase = 1;
    if (event.shiftKey) increase = 5;
    if (event.ctrlKey) increase = 10;
    const newHealth = Math.min(maxHealth, health + increase);
    setHealth(newHealth);
    addHealthLog("Cura", increase);
  };

*/

  const applyDamage = () => {
    const newHealth = Math.max(0, health - damageAmount);
    setHealth(newHealth);
    addHealthLog("Dano", damageAmount);
    setIsDamagePopupOpen(false);
    setDamageAmount(1);
  };

  const applyHealing = () => {
    const newHealth = Math.min(maxHealth, health + healingAmount);
    setHealth(newHealth);
    addHealthLog("Cura", healingAmount);
    setIsHealingPopupOpen(false);
    setHealingAmount(1);
  };

  const addHealthLog = (type, amount) => {
    const newLog = {
      id: Date.now(),
      type,
      amount,
      timestamp: new Date().toLocaleString()
    };
    setHealthLogs(prevLogs => [newLog, ...prevLogs]);
  };

  const handleHealthBarClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e, setter) => {
    setter(Number(e.target.value));
  };

  const handleHealthInputChange = (e) => {
    const newHealth = Number(e.target.value);
    setHealth(newHealth);
    setMaxHealth(newHealth);
  };

  const handleSpellSlotChange = (level, field, value) => {
    setSpellSlots(prevSlots => ({
      ...prevSlots,
      [level]: {
        ...prevSlots[level],
        [field]: field === "name" ? value : Number(value)
      }
    }));
  };

  const handleIconChange = (level, iconName) => {
    const iconMap = {
      FaCircle,
      FaSquare,
      FaStar
    };
    setSpellSlots(prevSlots => ({
      ...prevSlots,
      [level]: {
        ...prevSlots[level],
        icon: iconMap[iconName]
      }
    }));
  };

  const handleColorChange = (level, color) => {
    setSpellSlots(prevSlots => ({
      ...prevSlots,
      [level]: {
        ...prevSlots[level],
        color
      }
    }));
  };

  const handleSpellIconChange = (level, iconName) => {
    const iconMap = {
      FaMagic,
      FaFireAlt,
      FaBolt,
      FaSnowflake,
      FaCircle
    };
    setSpellSlots(prevSlots => ({
      ...prevSlots,
      [level]: {
        ...prevSlots[level],
        spellIcon: iconMap[iconName]
      }
    }));
  };

  const addSpellSlotLevel = () => {
    const newLevel = Object.keys(spellSlots).length + 1;
    setSpellSlots(prevSlots => ({
      ...prevSlots,
      [newLevel]: { name: `Skill ${newLevel}`, used: 0, total: 1, icon: FaCircle, color: "#FFFFFF", spellIcon: FaMagic }
    }));
  };

  const removeSpellSlotLevel = (level) => {
    setSpellSlots(prevSlots => {
      const newSlots = { ...prevSlots };
      delete newSlots[level];
      return newSlots;
    });
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Ficha do Personagem</h2>
    <div className="bg-[#40444b] p-6 rounded-lg shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">@Personagem</h2>
        <button
          onClick={toggleEdit}
          className="bg-[#7289da] text-white px-4 py-2 rounded hover:bg-[#677bc4]"
        >
          {isEditing ? "Salvar" : "Editar"}
        </button>
      </div>
      
      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">Vida</h3>
        <div className="flex items-center">
          <FaHeartBroken 
            className="text-red-500 mr-2 cursor-pointer" 
            onClick={handleHealthReduction}
          />
          <div 
            className="w-full bg-gray-700 rounded-full h-4 relative cursor-pointer overflow-hidden"
            onClick={handleHealthBarClick}
          >
            <div
              className="bg-red-500 h-full transition-all duration-300 ease-in-out"
              style={{ width: `${Math.min((health / maxHealth) * 100, 100)}%` }}
            ></div>
          </div>
          <FaHeart 
            className="text-red-500 ml-2 cursor-pointer" 
            onClick={handleHealthIncrease}
          />
          {isEditing ? (
            <input
              type="number"
              value={health}
              onChange={handleHealthInputChange}
              className="ml-2 w-16 bg-gray-700 text-white rounded px-2 py-1"
            />
          ) : (
            <span className="ml-2">{health}/{maxHealth}</span>
          )}
        </div>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">Experiência</h3>
        <div className="flex items-center">
          <FaUserAlt className="text-yellow-500 mr-2" />
          <div className="w-full bg-gray-700 rounded-full h-4">
            <div
              className="bg-yellow-500 rounded-full h-4"
              style={{ width: `${(xp / xpToNextLevel) * 100}%` }}
            ></div>
          </div>
          {isEditing ? (
            <input
              type="number"
              value={xp}
              onChange={(e) => handleInputChange(e, setXp)}
              className="ml-2 w-24 bg-gray-700 text-white rounded px-2 py-1"
            />
          ) : (
            <span className="ml-2">{xp}/{xpToNextLevel}</span>
          )}
        </div>
        <p className="mt-1">
          Nivel: 
          {isEditing ? (
            <input
              type="number"
              value={level}
              onChange={(e) => handleInputChange(e, setLevel)}
              className="ml-2 w-16 bg-gray-700 text-white rounded px-2 py-1"
            />
          ) : (
            level
          )}
        </p>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">AC</h3>
        <div className="flex items-center">
          <FaShieldAlt className="text-blue-500 mr-2" />
          {isEditing ? (
            <input
              type="number"
              value={armorClass}
              onChange={(e) => handleInputChange(e, setArmorClass)}
              className="w-16 bg-gray-700 text-white rounded px-2 py-1"
            />
          ) : (
            <span>{armorClass}</span>
          )}
        </div>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">Spell Slots e Skills</h3>
        {Object.entries(spellSlots).map(([level, { name, used, total, icon: Icon, color, spellIcon: SpellIcon }]) => (
          <div key={level} className={`flex items-center mb-1 ${isMobile && isEditing ? 'flex-wrap' : ''}`}>
            <SpellIcon className="mr-2" style={{ color: color }} />
            {isEditing ? (
              <>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => handleSpellSlotChange(level, "name", e.target.value)}
                  className={`${isMobile ? 'w-full mb-2' : 'w-24'} bg-gray-700 text-white rounded px-2 py-1 mr-2`}
                />
                <input
                  type="number"
                  value={used}
                  onChange={(e) => handleSpellSlotChange(level, "used", e.target.value)}
                  className={`${isMobile ? 'w-1/4' : 'w-12'} bg-gray-700 text-white rounded px-2 py-1 mr-1`}
                />
                <span>/</span>
                <input
                  type="number"
                  value={total}
                  onChange={(e) => handleSpellSlotChange(level, "total", e.target.value)}
                  className={`${isMobile ? 'w-1/4' : 'w-12'} bg-gray-700 text-white rounded px-2 py-1 ml-1`}
                />
                <select
                  value={Icon.name}
                  onChange={(e) => handleIconChange(level, e.target.value)}
                  className={`${isMobile ? 'w-full mt-2' : 'ml-2'} bg-gray-700 text-white rounded px-2 py-1`}
                >
                  <option value="FaCircle">Circulo</option>
                  <option value="FaSquare">Quadrado</option>
                  <option value="FaStar">Estrela</option>
                </select>
                <select
                  value={SpellIcon.name}
                  onChange={(e) => handleSpellIconChange(level, e.target.value)}
                  className={`${isMobile ? 'w-full mt-2' : 'ml-2'} bg-gray-700 text-white rounded px-2 py-1`}
                >
                  <option value="FaMagic">Magia</option>
                  <option value="FaFireAlt">Fogo</option>
                  <option value="FaBolt">Raio</option>
                  <option value="FaSnowflake">Gelo</option>
                  <option value="FaCircle">Circulo</option>
                </select>
                <input
                  type="color"
                  value={color}
                  onChange={(e) => handleColorChange(level, e.target.value)}
                  className={`${isMobile ? 'w-full mt-2' : 'ml-2'} bg-gray-700 rounded`}
                />
                <button
                  onClick={() => removeSpellSlotLevel(level)}
                  className={`${isMobile ? 'w-full mt-2' : 'ml-2'} text-red-500 hover:text-red-700`}
                >
                  <FaTrash />
                </button>
              </>
            ) : (
              <>
                <span className="mr-2">{name}:</span>
                {[...Array(total)].map((_, i) => (
                  <Icon
                    key={i}
                    className={`w-4 h-4 mr-1 cursor-pointer`}
                    style={{ color: i < used ? color : "#4a4a4a" }}
                    onClick={() => handleSpellSlotClick(level, i)}
                  />
                ))}
                <span className="ml-2">{used}/{total}</span>
              </>
            )}
          </div>
        ))}
        {isEditing && (
          <button
            onClick={addSpellSlotLevel}
            className="mt-4 bg-[#43b581] text-white px-4 py-2 rounded hover:bg-[#3ca374]">
            Nova Spell / Skill</button>
        )}
      </div>

      {isDamagePopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-[#40444b] p-6 rounded-lg shadow-lg w-64">
            <h3 className="text-xl font-bold mb-4">Aplicar Dano</h3>
            <input
              type="number"
              value={damageAmount}
              onChange={(e) => setDamageAmount(Number(e.target.value))}
              className="w-full bg-gray-700 text-white rounded px-2 py-1 mb-4"
            />
            <div className="flex justify-between">
              <button
                onClick={applyDamage}
                className="bg-[#f04747] text-white px-4 py-2 rounded hover:bg-[#d84040]">
                Aplicar
              </button>
              <button
                onClick={() => setIsDamagePopupOpen(false)}
                className="bg-[#7289da] text-white px-4 py-2 rounded hover:bg-[#677bc4]">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}

      {isHealingPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-[#40444b] p-6 rounded-lg shadow-lg w-64">
            <h3 className="text-xl font-bold mb-4">Aplicar Cura</h3>
            <input
              type="number"
              value={healingAmount}
              onChange={(e) => setHealingAmount(Number(e.target.value))}
              className="w-full bg-gray-700 text-white rounded px-2 py-1 mb-4"
            />
            <div className="flex justify-between">
              <button
                onClick={applyHealing}
                className="bg-[#43b581] text-white px-4 py-2 rounded hover:bg-[#3ca374]">
                Aplicar
              </button>
              <button
                onClick={() => setIsHealingPopupOpen(false)}
                className="bg-[#7289da] text-white px-4 py-2 rounded hover:bg-[#677bc4]">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-[#40444b] p-6 rounded-lg shadow-lg w-96">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold">Log de Combate</h3>
              <button onClick={closeModal} className="text-gray-300 hover:text-white">
                <FaTimes />
              </button>
            </div>
            <div className="max-h-96 overflow-y-auto">
              {healthLogs.map((log) => (
                <div key={log.id} className="mb-2 p-2 bg-[#36393f] rounded">
                  <p className="text-sm">
                    <span className={log.type === "Dano" ? "text-red-500" : "text-green-500"}>
                      {log.type}:
                    </span>{" "}
                    {log.amount} HP
                  </p>
                  <p className="text-xs text-gray-400">{log.timestamp}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
    </div>
  );
};
  
  export default Ficha;