import React from "react";
import { FaEdit, FaTrash } from "react-icons/fa";


const Jogadores = ({ isMobile }) => {
    return (
      <div>
        <h2 className="text-2xl font-bold mb-4">Jogadores</h2>
        <div className="overflow-x-auto">
          <table className="w-full bg-[#40444b] shadow-md rounded">
            <thead>
              <tr className="bg-[#2f3136] text-gray-300 uppercase text-sm leading-normal">
                <th className="py-3 px-2 text-left">Nome</th>
                {!isMobile && <th className="py-3 px-2 text-left">Classe</th>}
                {!isMobile && <th className="py-3 px-2 text-left">Personagem</th>}
                <th className="py-3 px-2 text-left">Ações</th>
              </tr>
            </thead>
            <tbody className="text-gray-300 text-sm font-light">
              {[1, 2, 3].map((player) => (
                <tr key={player} className="border-b border-[#2f3136] hover:bg-[#36393f]">
                  <td className="py-3 px-2 text-left whitespace-nowrap">Jogador {player}</td>
                  {!isMobile && <td className="py-3 px-2 text-left">Classe {player}</td>}
                  {!isMobile && <td className="py-3 px-2 text-left">Personagem {player}</td>}
                  <td className="py-3 px-2 text-left">
                  <button className="text-blue-500 mr-2">
                          <FaEdit />
                        </button>
                        <button className="text-red-500">
                          <FaTrash />
                        </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button className="mt-4 bg-[#43b581] text-white px-4 py-2 rounded hover:bg-[#3ca374]">
          Novo Jogador
        </button>
      </div>
    );
  };

  export default Jogadores;