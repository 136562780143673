import React from 'react';

const ListaSessao = ({ isMobile }) => {
    return (
      <div>
        <h2 className="text-2xl font-bold mb-4">Sessões</h2>
        <div className="overflow-x-auto">
          <table className="w-full bg-[#40444b] shadow-md rounded">
            <thead>
              <tr className="bg-[#2f3136] text-gray-300 uppercase text-sm leading-normal">
                <th className="py-3 px-2 text-left">Data</th>
                {!isMobile && <th className="py-3 px-2 text-left">Campanha</th>}
                {!isMobile && <th className="py-3 px-2 text-left">Jogadores</th>}
                <th className="py-3 px-2 text-left">Ações</th>
              </tr>
            </thead>
            <tbody className="text-gray-300 text-sm font-light">
              {[1, 2, 3].map((session) => (
                <tr key={session} className="border-b border-[#2f3136] hover:bg-[#36393f]">
                  <td className="py-3 px-2 text-left whitespace-nowrap">
                    {new Date().toLocaleDateString()}
                  </td>
                  {!isMobile && <td className="py-3 px-2 text-left">Campanha {session}</td>}
                  {!isMobile && <td className="py-3 px-2 text-left">4 jogadores</td>}
                  <td className="py-3 px-2 text-left">
                    <button className="bg-[#7289da] text-white px-2 py-1 rounded hover:bg-[#677bc4]">
                      Ver detalhes
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button className="mt-4 bg-[#43b581] text-white px-4 py-2 rounded hover:bg-[#3ca374]">
          Nova Sessão
        </button>
      </div>
    );
  };

  export default ListaSessao;