import React, { useState, useEffect } from 'react';
import { FaEnvelope, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { supabase } from '../utils/supabaseClient';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext'; // Alterado de useAuth para useAuthContext

const SignIn = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const navigate = useNavigate();
  const { updateUser } = useAuthContext(); // Alterado de useAuth para useAuthContext

  useEffect(() => {
    if (loginSuccess) {
      const timer = setTimeout(() => {
        console.log('Redirecionando para /site');
        navigate('/site');
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [loginSuccess, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
    validateField(name, value);
  };

  const validateField = (name, value) => {
    let newErrors = { ...errors };
    switch (name) {
      case 'email':
        newErrors.email = !/^\S+@\S+\.\S+$/.test(value) ? 'Formato de e-mail inválido' : '';
        break;
      default:
        break;
    }
    setErrors(newErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors({});

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: formData.email,
        password: formData.password,
      });

      if (error) {
        throw error;
      }
      
      console.log('Login bem-sucedido:', data);
      updateUser(data.user);
      setLoginSuccess(true);
    } catch (error) {
      let errorMessage;
      if (error.message.includes('Invalid login credentials')) {
        errorMessage = 'Usuário ou senha incorretos';
      } else {
        errorMessage = 'Ocorreu um erro durante o login. Tente novamente.';
      }
      console.error('Erro no login:', error.message);
      setErrors({ submit: errorMessage });
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="min-h-screen bg-[#36393f] flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-[#40444b] rounded-lg shadow-xl p-8 max-w-md w-full"
>
        <h2 className="text-3xl font-bold text-center mb-8 text-white">Entrar</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-white">Endereço de E-mail</label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FaEnvelope className="h-5 w-5 text-gray-500" />
              </div>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className="block w-full pl-10 pr-3 py-2 border border-gray-500 rounded-md leading-5 bg-[#2f3136] text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#7289da] focus:border-[#7289da] sm:text-sm"
                placeholder="nome@exemplo.com"
                required
              />
            </div>
            {errors.email && <p className="mt-2 text-sm text-red-500">{errors.email}</p>}
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-white">Senha</label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FaLock className="h-5 w-5 text-gray-500" />
              </div>
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                id="password"
                value={formData.password}
                onChange={handleChange}
                className="block w-full pl-10 pr-10 py-2 border border-gray-500 rounded-md leading-5 bg-[#2f3136] text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#7289da] focus:border-[#7289da] sm:text-sm"
                placeholder="••••••••"
                required
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="text-gray-500 hover:text-gray-400 focus:outline-none focus:text-gray-400"
                >
                  {showPassword ? <FaEyeSlash className="h-5 w-5" /> : <FaEye className="h-5 w-5" />}
                </button>
              </div>
            </div>
            {errors.password && <p className="mt-2 text-sm text-red-500">{errors.password}</p>}
          </div>
          <div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="submit"
              disabled={isLoading}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#7289da] hover:bg-[#5a6eae] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#7289da] disabled:opacity-50"
            >
              {isLoading ? 'Entrando...' : 'Entrar'}
            </motion.button>
          </div>
        </form>
        {errors.submit && <p className="mt-4 text-center text-sm text-red-500">{errors.submit}</p>}
        <div className="mt-4 text-center">
          <p className="text-sm text-white">Não tem uma conta?{' '}<a href="/registro" className="text-[#7289da] hover:text-[#5a6eae] font-bold font-medium">Registre-se</a>
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default SignIn;