import React, { useState } from "react";
import { FaPlus, FaEdit, FaTrashAlt, FaCheck, FaTimes } from "react-icons/fa";

const Inventario = () => {
    const [items, setItems] = useState([
        { id: 1, name: "Sword", quantity: 1, value: 100 },
        { id: 2, name: "Health Potion", quantity: 3, value: 50 },
        { id: 3, name: "Gold Coins", quantity: 100, value: 1 },
    ]);
    const [newItem, setNewItem] = useState({ name: "", quantity: 0, value: 0 });
    const [editingItem, setEditingItem] = useState(null);

    const addItem = () => {
        if (newItem.name && newItem.quantity > 0 && newItem.value >= 0) {
            setItems([...items, { ...newItem, id: Date.now() }]);
            setNewItem({ name: "", quantity: 0, value: 0 });
        }
    };

    const deleteItem = (id) => {
        setItems(items.filter((item) => item.id !== id));
    };

    const startEditing = (item) => {
        setEditingItem(item);
    };

    const saveEdit = () => {
        setItems(items.map((item) => (item.id === editingItem.id ? editingItem : item)));
        setEditingItem(null);
    };

    const cancelEdit = () => {
        setEditingItem(null);
    };

    const totalValue = items.reduce((total, item) => total + item.quantity * item.value, 0);

    return (
        <div>
            <h2 className="text-2xl font-bold mb-4">Inventário do Grupo</h2>
            <div className="mb-6">
                <div className="flex space-x-2">
                    <div className="flex flex-col flex-grow">
                        <label htmlFor="itemName" className="text-sm mb-1">ITEM</label>
                        <input
                            id="itemName"
                            type="text"
                            className="bg-[#40444b] text-white px-3 py-2 rounded"
                            value={newItem.name}
                            onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
                        />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="quantity" className="text-sm mb-1">QTD</label>
                        <input
                            id="quantity"
                            type="number"
                            className="bg-[#40444b] text-white px-3 py-2 rounded w-24"
                            value={newItem.quantity}
                            onChange={(e) => setNewItem({ ...newItem, quantity: parseInt(e.target.value) || 0 })}
                        />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="value" className="text-sm mb-1">VALOR (UND)</label>
                        <input
                            id="value"
                            type="number"
                            className="bg-[#40444b] text-white px-3 py-2 rounded w-24"
                            value={newItem.value}
                            onChange={(e) => setNewItem({ ...newItem, value: parseInt(e.target.value) || 0 })}
                        />
                    </div>
                    <button onClick={addItem} className="bg-[#43b581] text-white px-2 py-1 rounded hover:bg-[#3ca374]">
                        <FaPlus />
                    </button>
                </div>
            </div>
            <div>
                <table className="w-full bg-[#40444b] rounded overflow-hidden">
                    <thead>
                        <tr className="bg-[#2f3136] text-gray-300 uppercase text-sm leading-normal">
                            <th className="py-3 px-2 text-left">NOME</th>
                            <th className="py-3 px-2 text-left">QTD</th>
                            <th className="py-3 px-2 text-left">VALOR (UND)</th>
                            <th className="py-3 px-2 text-left">AÇÕES</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item) => (
                            <tr key={item.id} className="text-gray-300 text-sm font-light border-b border-[#2f3136] hover:bg-[#36393f]">
                                {editingItem && editingItem.id === item.id ? (
                                    <>
                                        <td className="py-3 px-2">
                                            <input
                                                type="text"
                                                value={editingItem.name}
                                                onChange={(e) => setEditingItem({ ...editingItem, name: e.target.value })}
                                                className="bg-[#2f3136] text-gray-300 text-sm font-light px-2 py-1 rounded w-full"
                                            />
                                        </td>
                                        <td className="py-3 px-2">
                                            <input
                                                type="number"
                                                value={editingItem.quantity}
                                                onChange={(e) => setEditingItem({ ...editingItem, quantity: parseInt(e.target.value) || 0 })}
                                                className="bg-[#2f3136] text-gray-300 text-sm font-light px-2 py-1 rounded w-full"
                                            />
                                        </td>
                                        <td className="py-3 px-2">
                                            <input
                                                type="number"
                                                value={editingItem.value}
                                                onChange={(e) => setEditingItem({ ...editingItem, value: parseInt(e.target.value) || 0 })}
                                                className="bg-[#2f3136] text-green-400 text-sm font-light px-2 py-1 rounded w-full"
                                            />
                                        </td>
                                        <td className="py-3 px-2">
                                            <button onClick={saveEdit} className="text-green-500 mr-2">
                                                <FaCheck />
                                            </button>
                                            <button onClick={cancelEdit} className="text-red-500">
                                                <FaTimes />
                                            </button>
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td className="py-3 px-2">{item.name}</td>
                                        <td className="py-3 px-2">{item.quantity.toLocaleString()}</td>
                                        <td className="py-3 px-2 text-green-400">{item.value.toLocaleString()} gold</td>
                                        <td className="py-3 px-2">
                                            <button onClick={() => startEditing(item)} className="text-[#7289da] hover:text-[#677bc4] mr-2">
                                                <FaEdit />
                                            </button>
                                            <button onClick={() => deleteItem(item.id)} className="text-red-500 hover:text-red-700">
                                                <FaTrashAlt />
                                            </button>
                                        </td>
                                    </>
                                )}
                            </tr>
                        ))}
                        <tr className="bg-[#242529]">
                            <td className="text-gray-300 py-3 px-2 text-left font-bold text-sm">TOTAL</td>
                            <td className="text-gray-300 py-3 px-2 text-left font-bold text-sm">{items.reduce((total, item) => total + item.quantity, 0).toLocaleString()}</td>
                            <td className="text-green-400 py-3 px-2 text-left font-bold text-sm">{totalValue.toLocaleString()} Gold</td>
                            <td className="text-gray-300 py-3 px-2 text-left font-bold text-sm"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Inventario;