import React, { useState } from 'react';
import { FaUser, FaEnvelope, FaLock, FaEye, FaEyeSlash } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { supabase } from '../utils/supabaseClient'; // Ajuste o caminho conforme necessário
import { useNavigate } from 'react-router-dom'; // Atualize a importação para usar useNavigate

const UserRegistration = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    username: '',
    password: ''
  });
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  
  const navigate = useNavigate(); // Instanciar o useNavigate

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
    validateField(name, value);
    if (name === 'password') {
      checkPasswordStrength(value);
    }
  };

  const validateField = (name, value) => {
    let newErrors = { ...errors };
    switch (name) {
      case 'name':
        newErrors.name = value.length < 2 ? 'O nome deve ter pelo menos 2 caracteres' : '';
        break;
      case 'email':
        newErrors.email = !/^\S+@\S+\.\S+$/.test(value) ? 'Formato de e-mail inválido' : '';
        break;
      case 'username':
        const usernameRegex = /^[a-zA-Z0-9]+$/; // Regex para letras e números sem espaços ou caracteres especiais
        newErrors.username = value.length < 3 ? 'O nome de usuário deve ter pelo menos 3 caracteres' :
          !usernameRegex.test(value) ? 'O nome de usuário deve conter apenas letras e números' : '';
        break;
      case 'password':
        newErrors.password = value.length < 8 ? 'A senha deve ter pelo menos 8 caracteres' : '';
        break;
      default:
        break;
    }
    setErrors(newErrors);
  };

  const checkPasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (password.match(/[a-z]+/)) strength++;
    if (password.match(/[A-Z]+/)) strength++;
    if (password.match(/[0-9]+/)) strength++;
    if (password.match(/[$@#&!]+/)) strength++;
    setPasswordStrength(strength);
  };

  const getPasswordStrengthColor = () => {
    switch (passwordStrength) {
      case 0:
      case 1:
        return 'bg-red-500';
      case 2:
      case 3:
        return 'bg-yellow-500';
      case 4:
      case 5:
        return 'bg-green-500';
      default:
        return 'bg-gray-300';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors({});
  
    // Validações
    validateField('name', formData.name);
    validateField('email', formData.email);
    validateField('username', formData.username);
    validateField('password', formData.password);
  
    // Verifique se existem erros antes de prosseguir
    if (Object.values(errors).some(error => error)) {
      setIsLoading(false);
      return; // Não prosseguir se houver erros
    }
  
    try {
      // Verificar se o username já existe (case insensitive)
      const { data: existingProfiles, error: usernameCheckError } = await supabase
        .from('profiles')
        .select('username');
  
      if (usernameCheckError) {
        throw new Error('Erro ao verificar o nome de usuário: ' + usernameCheckError.message);
      }
  
      // Verifique se o username já está em uso, ignorando case
      const usernameExists = existingProfiles.some(profile => profile.username.toLowerCase() === formData.username.toLowerCase());
      if (usernameExists) {
        throw new Error('Este nome de usuário já está em uso. Tente outro.');
      }
  
      // Tentar registrar o usuário
      const { data: authData, error: authError } = await supabase.auth.signUp({
        email: formData.email,
        password: formData.password,
      });
  
      if (authError) {
        if (authError.message === 'User already registered') {
          throw new Error('Este e-mail já está registrado. Tente outro.');
        }
        throw authError; // Lança outros erros de autenticação
      }
  
      // Salvar informações adicionais na tabela de perfis
      const { data: profileData, error: profileError } = await supabase
        .from('profiles')
        .insert([
          {
            user_id: authData.user.id,
            full_name: formData.name,
            username: formData.username,
            email: formData.email
          }
        ]);
  
      if (profileError) throw profileError;
  
      console.log('Registro bem-sucedido:', authData, profileData);
      navigate('/site');
  
    } catch (error) {
      console.error('Erro no registro:', error.message);
      setErrors({ ...errors, submit: error.message });
  
    // Limpar os dados após um pequeno delay
    setTimeout(() => {
      window.location.reload();
    }, 3000); // Delay de 3 segundos
    } finally {
      setIsLoading(false);
    }
  };  
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="min-h-screen bg-[#36393f] flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="bg-[#40444b] rounded-lg shadow-xl p-8 max-w-md w-full"
      >
        <h2 className="text-3xl font-bold text-center mb-8 text-white">Criar uma Conta</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-white">Nome Completo</label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FaUser className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                className="block w-full pl-10 pr-3 py-2 border border-[#4f545c] rounded-md leading-5 bg-[#2f3136] text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#7289da] focus:border-[#7289da] sm:text-sm"
                placeholder="João da Silva"
                required
              />
            </div>
            {errors.name && <p className="mt-2 text-sm text-red-500">{errors.name}</p>}
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-white">Endereço de E-mail</label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FaEnvelope className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                className="block w-full pl-10 pr-3 py-2 border border-[#4f545c] rounded-md leading-5 bg-[#2f3136] text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#7289da] focus:border-[#7289da] sm:text-sm"
                placeholder="voce@exemplo.com"
                required
              />
            </div>
            {errors.email && <p className="mt-2 text-sm text-red-500">{errors.email}</p>}
          </div>
          <div>
            <label htmlFor="username" className="block text-sm font-medium text-white">Nome de Usuário</label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FaUser className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                name="username"
                id="username"
                value={formData.username}
                onChange={handleChange}
                className="block w-full pl-10 pr-3 py-2 border border-[#4f545c] rounded-md leading-5 bg-[#2f3136] text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#7289da] focus:border-[#7289da] sm:text-sm"
                placeholder="usuariolegal123"
                required
              />
            </div>
            {errors.username && <p className="mt-2 text-sm text-red-500">{errors.username}</p>}
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-white">Senha</label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FaLock className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type={showPassword ? 'text' : 'password'}
                name="password"
                id="password"
                value={formData.password}
                onChange={handleChange}
                className="block w-full pl-10 pr-10 py-2 border border-[#4f545c] rounded-md leading-5 bg-[#2f3136] text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#7289da] focus:border-[#7289da] sm:text-sm"
                placeholder="••••••••"
                required
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500"
                >
                  {showPassword ? <FaEyeSlash className="h-5 w-5" /> : <FaEye className="h-5 w-5" />}
                </button>
              </div>
            </div>
            {errors.password && <p className="mt-2 text-sm text-red-500">{errors.password}</p>}
            <div className="mt-2">
              <div className="flex justify-between mb-1">
                <span className="text-sm font-medium text-white">Força da Senha</span>
                <span className="text-sm font-medium text-white">
                  {passwordStrength === 0 ? 'Fraca' : passwordStrength <= 2 ? 'Média' : 'Forte'}
                </span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div
                  className={`${getPasswordStrengthColor()} h-2.5 rounded-full`}
                  style={{ width: `${(passwordStrength / 5) * 100}%` }}
                ></div>
              </div>
            </div>
          </div>
          <div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              type="submit"
              disabled={isLoading}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#7289da] hover:bg-[#5b6eae] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#7289da] disabled:opacity-50"
            >
              {isLoading ? 'Registrando...' : 'Inscrever-se'}
            </motion.button>
          </div>
          {errors.submit && <p className="mt-2 text-sm text-center text-red-500">{errors.submit}</p>}
        </form>
        <p className="mt-4 text-center text-sm text-white">Já tem uma conta?{' '}
          <a href="/login" className="text-[#7289da] hover:text-[#5a6eae] font-bold font-medium">Faça login</a>
        </p>
      </motion.div>
    </div>
  );
};

export default UserRegistration;
