// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/login';
import Registro from './components/registro';
import AddCampanha from './components/addcampanha';
import Site from './components/site';
import PrivateRoute from './components/PrivateRoute'; // Certifique-se de que o caminho está correto
import { AuthProvider } from './contexts/AuthContext';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/addcampanha" element={<AddCampanha />} />
          <Route path="/registro" element={<Registro />} />
          <Route path="/site" element={<PrivateRoute><Site /></PrivateRoute>} />
          <Route path="/" element={<Navigate to="/login" replace />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
