import React, { useState, useEffect } from "react";
import { FaChevronLeft, FaChevronRight, FaPlus, FaEdit, FaTrash } from "react-icons/fa";
import { motion } from "framer-motion";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const Calendario = () => {
  const [currentMonth, setCurrentMonth] = useState(0); // Hammer
  const [currentYear, setCurrentYear] = useState(1495);
  const [selectedDate, setSelectedDate] = useState(null);
  const [events, setEvents] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalEvent, setModalEvent] = useState({ name: "", description: "", color: "#000000" });
  const [modalMode, setModalMode] = useState("add");
  const [editingEventIndex, setEditingEventIndex] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);

  const months = [
    "Hammer", "Alturiak", "Ches", "Tarsakh", "Mirtul", "Kythorn",
    "Flamerule", "Eleasis", "Eleint", "Marpenoth", "Uktar", "Nightal"
  ];

  const predefinedColors = ["#FF5733", "#33FF57", "#3357FF", "#FF33F1", "#33FFF1", "#F1FF33"];

  useEffect(() => {
    const storedSelectedDates = localStorage.getItem("selectedDates");
    if (storedSelectedDates) {
      setSelectedDates(JSON.parse(storedSelectedDates));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedDates", JSON.stringify(selectedDates));
  }, [selectedDates]);

  useEffect(() => {
    if (currentMonth > 11) {
      setCurrentMonth(0);
      setCurrentYear(prevYear => prevYear + 1);
    } else if (currentMonth < 0) {
      setCurrentMonth(11);
      setCurrentYear(prevYear => prevYear - 1);
    }
  }, [currentMonth]);

  const handlePrevMonth = () => {
    setCurrentMonth(prev => prev - 1);
  };

  const handleNextMonth = () => {
    setCurrentMonth(prev => prev + 1);
  };

  const handleDateClick = (day) => {
    setSelectedDate(day);
  };

  const handleDateDoubleClick = (day) => {
    const dateKey = `${currentYear}-${currentMonth}-${day}`;
    setSelectedDates((prev) => {
      if (prev.includes(dateKey)) {
        return prev.filter((d) => d !== dateKey);
      } else {
        return [...prev, dateKey];
      }
    });
  };

  const handleAddEvent = () => {
    if (selectedDate) {
      setModalMode("add");
      setModalEvent({ name: "", description: "", color: predefinedColors[0] });
      setIsModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalEvent({ name: "", description: "", color: predefinedColors[0] });
    setEditingEventIndex(null);
  };

  const handleModalSubmit = (e) => {
    e.preventDefault();
    if (modalEvent.name && modalEvent.color) {
      if (modalMode === "add") {
        setEvents((prev) => ({
          ...prev,
          [`${currentYear}-${currentMonth}-${selectedDate}`]: [
            ...(prev[`${currentYear}-${currentMonth}-${selectedDate}`] || []),
            { name: modalEvent.name, description: modalEvent.description, color: modalEvent.color },
          ],
        }));
      } else if (modalMode === "edit" && editingEventIndex !== null) {
        setEvents((prev) => {
          const updatedEvents = [...prev[`${currentYear}-${currentMonth}-${selectedDate}`]];
          updatedEvents[editingEventIndex] = { ...modalEvent };
          return {
            ...prev,
            [`${currentYear}-${currentMonth}-${selectedDate}`]: updatedEvents,
          };
        });
      }
      handleModalClose();
    }
  };

  const handleEditEvent = (index) => {
    const currentEvents = events[`${currentYear}-${currentMonth}-${selectedDate}`];
    if (currentEvents && currentEvents[index]) {
      setModalMode("edit");
      setModalEvent({ ...currentEvents[index] });
      setEditingEventIndex(index);
      setIsModalOpen(true);
    }
  };

  const handleDeleteEvent = (index) => {
    setEvents((prev) => {
      const updatedEvents = [...prev[`${currentYear}-${currentMonth}-${selectedDate}`]];
      updatedEvents.splice(index, 1);
      return {
        ...prev,
        [`${currentYear}-${currentMonth}-${selectedDate}`]: updatedEvents,
      };
    });
  };

  const handleDragStart = (e, day, eventIndex) => {
    e.dataTransfer.setData("text/plain", JSON.stringify({ day, eventIndex }));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, targetDay) => {
    e.preventDefault();
    const { day: sourceDay, eventIndex } = JSON.parse(e.dataTransfer.getData("text"));
    const sourceKey = `${currentYear}-${currentMonth}-${sourceDay}`;
    const targetKey = `${currentYear}-${currentMonth}-${targetDay}`;

    setEvents((prev) => {
      const updatedEvents = { ...prev };
      const [movedEvent] = updatedEvents[sourceKey].splice(eventIndex, 1);
      updatedEvents[targetKey] = [...(updatedEvents[targetKey] || []), movedEvent];
      return updatedEvents;
    });
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Calendario de Harptos!</h2>
      <div className="bg-[#2f3136] p-6 rounded shadow">
        <div className="flex justify-between items-center mb-6">
          <button
            onClick={handlePrevMonth}
            className="p-2 rounded-full bg-[#7289da] text-white hover:bg-[#677bc4] focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
            aria-label="Previous month"
          >
            <FaChevronLeft />
          </button>
          <div className="text-center">
            <h2 className="text-2xl font-bold text-white">{months[currentMonth]}</h2>
            <p className="text-lg text-gray-300">Year: {currentYear}</p>
          </div>
          <button
            onClick={handleNextMonth}
            className="p-2 rounded-full bg-[#7289da] text-white hover:bg-[#677bc4] focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
            aria-label="Next month"
          >
            <FaChevronRight />
          </button>
        </div>
        <div className="grid grid-cols-10 gap-2 mb-4">
          {["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X"].map((day) => (
            <div key={day} className="text-center font-semibold text-gray-300">
              {day}
            </div>
          ))}
        </div>
        <div className="grid grid-cols-10 gap-2">
          {Array.from({ length: 30 }, (_, i) => i + 1).map((day) => {
            const dateKey = `${currentYear}-${currentMonth}-${day}`;
            return (
              <motion.div
                key={day}
                className={`p-2 rounded-lg ${selectedDate === day ? "bg-[#36393f]" : "bg-[#40444b]"} ${selectedDates.includes(dateKey) ? "border-2 border-red-500 bg-[#36393f]" : ""} cursor-pointer hover:bg-[#36393f] transition-colors duration-200 shadow relative aspect-square`}
                onClick={() => handleDateClick(day)}
                onDoubleClick={() => handleDateDoubleClick(day)}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, day)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <div className="flex justify-between items-start h-full">
                  <div className="absolute top-1 left-1 font-semibold text-gray-300 text-xs">{day}</div>
                  {events[dateKey]?.map((event, index) => (
                    <div
                      key={index}
                      className="absolute bottom-1 left-1 right-1 text-xs truncate"
                      style={{ color: event.color }}
                      draggable
                      onDragStart={(e) => handleDragStart(e, day, index)}
                    >
                      {event.name}
                    </div>
                  ))}
                </div>
              </motion.div>
            );
          })}
        </div>
        {selectedDate && (
          <div className="mt-4">
            <button
              onClick={handleAddEvent}
              className="flex items-center justify-center w-full p-2 bg-[#43b581] text-white rounded hover:bg-[#3ca374] focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
            >
              <FaPlus className="mr-2" /> Add Event
            </button>
            {events[`${currentYear}-${currentMonth}-${selectedDate}`]?.map((event, index) => (
              <div key={index} className="mt-2 p-2 bg-[#40444B] rounded flex justify-between items-center">
                <span style={{ color: event.color }}>{event.name}</span>
                <div className="flex space-x-2">
                  <button
                    onClick={() => handleEditEvent(index)}
                    className="text-blue-400 hover:text-blue-300"
                  >
                    <FaEdit />
                  </button>
                  <button
                    onClick={() => handleDeleteEvent(index)}
                    className="text-red-400 hover:text-red-300"
                  >
                    <FaTrash />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-[#40444b] p-6 rounded-lg shadow-xl w-full max-w-sm">
              <h3 className="text-xl font-semibold mb-4 text-gray-100">{modalMode === "add" ? "Add New Event" : "Edit Event"}</h3>
              <form onSubmit={handleModalSubmit}>
                <div className="mb-4">
                  <label htmlFor="eventName" className="block text-sm font-medium text-gray-300">Event Name</label>
                  <input
                    type="text"
                    id="eventName"
                    value={modalEvent.name}
                    onChange={(e) => setModalEvent({ ...modalEvent, name: e.target.value })}
                    className="mt-1 block w-full rounded-md bg-[#2f3136] border-[#40444b] text-white shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="eventDescription" className="block text-sm font-medium text-gray-300">Event Description</label>
                  <ReactQuill
                    value={modalEvent.description}
                    onChange={(content) => setModalEvent({ ...modalEvent, description: content })}
                    theme="bubble"
                    className="mt-1 block w-full rounded-md bg-[#2f3136] border-[#40444b] text-white shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-300">Event Color</label>
                  <div className="flex justify-between mt-2">
                    {predefinedColors.map((color) => (
                      <button
                        key={color}
                        type="button"
                        className={`w-8 h-8 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white ${modalEvent.color === color ? "ring-2 ring-offset-2 ring-offset-gray-800 ring-white" : ""}`}
                        style={{ backgroundColor: color }}
                        onClick={() => setModalEvent({ ...modalEvent, color })}
                      />
                    ))}
                  </div>
                </div>
                <div className="flex justify-end space-x-2">
                  <button
                    type="button"
                    onClick={handleModalClose}
                    className="px-4 py-2 bg-[#f04747] text-gray-300 rounded hover:bg-[#d84040] focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-[#43b581] text-white rounded hover:bg-[#3ca374] focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
                  >
                    {modalMode === "add" ? "Add Event" : "Save Changes"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Calendario;