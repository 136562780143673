import React, { useState, useEffect, useRef } from "react";
import { FaUsers, FaDiceD20, FaBold, FaItalic, FaUnderline, FaPalette, FaTextHeight, FaUpload } from "react-icons/fa";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";

registerLocale("pt-BR", ptBR);

const AddCampanha = () => {
  const [campaignName, setCampaignName] = useState("");
  const [campaignDescription, setCampaignDescription] = useState("");
  const [maxPlayers, setMaxPlayers] = useState(4);
  const [startLevel, setStartLevel] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [harptosDate, setHarptosDate] = useState({ month: "", day: "", year: "" });
  const [errors, setErrors] = useState({});
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [showFontSizePicker, setShowFontSizePicker] = useState(false);
  const [activeButtons, setActiveButtons] = useState({});
  const [campaignImage, setCampaignImage] = useState(null);

  const colorPickerRef = useRef(null);
  const fontSizePickerRef = useRef(null);
  const descriptionRef = useRef(null);

  const harptosMonths = [
    "Hammer", "Alturiak", "Ches", "Tarsakh", "Mirtul", "Kythorn",
    "Flamerule", "Eleasis", "Eleint", "Marpenoth", "Uktar", "Nightal"
  ];

  const colors = ["#CCCCCC", "#FFFFFF", "#ED4245", "#5865F2", "#57F287", "#FEE75C", "#EB459E", "#F26522"];
  const fontSizes = ["2px", "3px", "4px", "5px"];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
        setShowColorPicker(false);
      }
      if (fontSizePickerRef.current && !fontSizePickerRef.current.contains(event.target)) {
        setShowFontSizePicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const validateForm = () => {
    let newErrors = {};
    if (!campaignName.trim()) newErrors.campaignName = "Preencha nome da campanha";
    if (!campaignDescription.trim()) newErrors.campaignDescription = "Preencha a descrição";
    if (maxPlayers < 1) newErrors.maxPlayers = "Necessário ao menos um jogador";
    if (startLevel < 1 || startLevel > 20) newErrors.startLevel = "Selecione o Nível Inicial";
    if (!harptosDate.month || !harptosDate.day || !harptosDate.year) newErrors.harptosDate = "Preencha data inicial do Calendário de Harptos";
    if (!campaignImage) newErrors.campaignImage = "Necessário imagem da campanha";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log("Formulário enviado com sucesso");
      // Here you would typically send the data to a server
    }
  };

  const formatText = (command, value = null) => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
  
      if (command === "formatBlock") {
        const blockquote = document.createElement("blockquote");
        blockquote.className = "border-l-4 border-gray-300 pl-4 italic";
  
        // Extrair o conteúdo da seleção
        const contents = range.extractContents();
        blockquote.appendChild(contents);
  
        // Inserir o blockquote no local da seleção
        range.insertNode(blockquote);
  
        // Se a seleção estava vazia, mover o cursor para dentro do blockquote
        range.setStartAfter(blockquote);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
      } else {
        document.execCommand(command, false, value);
      }
  
      setActiveButtons(prev => ({
        ...prev,
        [command]: !prev[command]
      }));
    }
  
    descriptionRef.current.focus();
  };
  
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCampaignImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="min-h-screen bg-[#36393f] flex items-center justify-center p-4">
      <div className="max-w-3xl mx-auto bg-[#40444b] rounded-lg shadow-xl overflow-hidden">
        <div className="px-4 py-5 sm:p-6">
        <h2 className="text-3xl font-bold text-center mb-8 text-white">Criar uma Campanha</h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="campaignName" className="block text-sm font-medium text-white">
                Nome da Campanha
              </label>
              <input
                type="text"
                id="campaignName"
                value={campaignName}
                onChange={(e) => setCampaignName(e.target.value)}
                className={`mt-1 block w-full border ${errors.campaignName ? "border-red-500" : "border-gray-600"} rounded-md shadow-sm py-2 px-3 bg-[#2f3136] text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#7289da] focus:border-[#7289da] sm:text-sm`}
              />
              {errors.campaignName && <p className="mt-2 text-sm text-red-500">{errors.campaignName}</p>}
            </div>

            <div>
              <label htmlFor="campaignDescription" className="block text-sm font-medium text-white">
                Descrição da Campanha
              </label>
              <div className="mt-1 relative">
                <div className="flex space-x-2 mb-2">
                  <button
                    type="button"
                    onClick={() => formatText("bold")}
                    className={`p-2 ${activeButtons.bold ? "bg-gray-600" : ""} text-white hover:bg-gray-600 rounded`}
                  >
                    <FaBold />
                  </button>
                  <button
                    type="button"
                    onClick={() => formatText("italic")}
                    className={`p-2 ${activeButtons.italic ? "bg-gray-600" : ""} text-white hover:bg-gray-600 rounded`}
                  >
                    <FaItalic />
                  </button>
                  <button
                    type="button"
                    onClick={() => formatText("underline")}
                    className={`p-2 ${activeButtons.underline ? "bg-gray-600" : ""} text-white hover:bg-gray-600 rounded`}
                  >
                    <FaUnderline />
                  </button>
                  <div className="relative" ref={colorPickerRef}>
                    <button
                      type="button"
                      onClick={() => setShowColorPicker(!showColorPicker)}
                      className="p-2 text-white hover:bg-gray-600 rounded"
                    >
                      <FaPalette />
                    </button>
                    {showColorPicker && (
                      <div className="absolute top-10 left-0 bg-gray-700 border border-gray-600 rounded shadow-lg p-2 flex space-x-1">
                        {colors.map((color) => (
                          <button
                            key={color}
                            type="button"
                            onClick={() => formatText("foreColor", color)}
                            className={`w-6 h-6 rounded-full`}
                            style={{ backgroundColor: color }}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="relative" ref={fontSizePickerRef}>
                    <button
                      type="button"
                      onClick={() => setShowFontSizePicker(!showFontSizePicker)}
                      className="p-2 text-white hover:bg-gray-600 rounded"
                    >
                      <FaTextHeight />
                    </button>
                    {showFontSizePicker && (
                      <div className="absolute top-10 left-0 bg-gray-700 border border-gray-600 rounded shadow-lg p-2">
                        {fontSizes.map((size) => (
                          <button
                            key={size}
                            type="button"
                            onClick={() => formatText("fontSize", size)}
                            className="block w-full text-left px-2 py-1 hover:bg-gray-600 text-white"
                          >
                            {size}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  ref={descriptionRef}
                  contentEditable
                  onInput={(e) => setCampaignDescription(e.target.innerHTML)}
                  className={`block w-full border ${errors.campaignDescription ? "border-red-500" : "border-gray-600"} rounded-md shadow-sm py-2 px-3 bg-[#2f3136] text-white focus:outline-none focus:ring-2 focus:ring-[#7289da] focus:border-[#7289da] sm:text-sm min-h-[100px]`}
                />
              </div>
              {errors.campaignDescription && <p className="mt-2 text-sm text-red-500">{errors.campaignDescription}</p>}
            </div>

            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
              <div>
                <label htmlFor="maxPlayers" className="block text-sm font-medium text-white">
                  Máx. Jogadores
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FaUsers className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="number"
                    id="maxPlayers"
                    value={maxPlayers}
                    onChange={(e) => setMaxPlayers(parseInt(e.target.value))}
                    className={`block w-full pl-10 border ${errors.maxPlayers ? "border-red-500" : "border-gray-600"} rounded-md shadow-sm py-2 px-3 bg-[#2f3136] text-white focus:outline-none focus:ring-2 focus:ring-[#7289da] focus:border-[#7289da] sm:text-sm`}
                    min="1"
                  />
                </div>
                {errors.maxPlayers && <p className="mt-2 text-sm text-red-500">{errors.maxPlayers}</p>}
              </div>

              <div>
                <label htmlFor="startLevel" className="block text-sm font-medium text-white">
                  Nível Inicial
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <FaDiceD20 className="h-5 w-5 text-gray-400" />
                  </div>
                  <select
                    id="startLevel"
                    value={startLevel}
                    onChange={(e) => setStartLevel(parseInt(e.target.value))}
                    className={`block w-full pl-10 border ${errors.startLevel ? "border-red-500" : "border-gray-600"} rounded-md shadow-sm py-2 px-3 bg-[#2f3136] text-white focus:outline-none focus:ring-2 focus:ring-[#7289da] focus:border-[#7289da] sm:text-sm`}
                  >
                    {[...Array(20)].map((_, i) => (
                      <option key={i + 1} value={i + 1}>{i + 1}</option>
                    ))}
                  </select>
                </div>
                {errors.startLevel && <p className="mt-2 text-sm text-red-500">{errors.startLevel}</p>}
              </div>
            </div>

            <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
              <div>
                <label htmlFor="startDate" className="block text-sm font-medium text-white">
                  Data de Início (Gregoriano)
                </label>
                <DatePicker
                  id="startDate"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="mt-1 block w-full border border-gray-600 rounded-md shadow-sm py-2 px-3 bg-[#2f3136] text-white focus:outline-none focus:ring-2 focus:ring-[#7289da] focus:border-[#7289da] sm:text-sm"
                  dateFormat="dd/MM/yyyy"
                  locale="pt-BR"
                />
              </div>

              <div>
                <label htmlFor="harptosDate" className="block text-sm font-medium text-white">
                Data de Início (Calendario de Harptos)
                </label>
                <div className="mt-1 grid grid-cols-3 gap-2">
                  <select
                    id="harptosDay"
                    value={harptosDate.day}
                    onChange={(e) => setHarptosDate({...harptosDate, day: e.target.value})}
                    className={`block w-full border ${errors.harptosDate ? "border-red-500" : "border-gray-600"} rounded-md shadow-sm py-2 px-3 bg-[#2f3136] text-white focus:outline-none focus:ring-2 focus:ring-[#7289da] focus:border-[#7289da] sm:text-sm`}
                  >
                    <option value="">Dia</option>
                    {[...Array(30)].map((_, i) => (
                      <option key={i + 1} value={i + 1}>{i + 1}</option>
                    ))}
                  </select>
                  <select
                    id="harptosMonth"
                    value={harptosDate.month}
                    onChange={(e) => setHarptosDate({...harptosDate, month: e.target.value})}
                    className={`block w-full border ${errors.harptosDate ? "border-red-500" : "border-gray-600"} rounded-md shadow-sm py-2 px-3 bg-[#2f3136] text-white focus:outline-none focus:ring-2 focus:ring-[#7289da] focus:border-[#7289da] sm:text-sm`}
                  >
                    <option value="">Mês</option>
                    {harptosMonths.map((month) => (
                      <option key={month} value={month}>{month}</option>
                    ))}
                  </select>
                  <input
                    type="number"
                    id="harptosYear"
                    value={harptosDate.year}
                    onChange={(e) => setHarptosDate({...harptosDate, year: e.target.value})}
                    placeholder="Ano"
                    className={`block w-full border ${errors.harptosDate ? "border-red-500" : "border-gray-600"} rounded-md shadow-sm py-2 px-3 bg-[#2f3136] text-white focus:outline-none focus:ring-2 focus:ring-[#7289da] focus:border-[#7289da] sm:text-sm`}
                    min="1"
                  />
                </div>
                {errors.harptosDate && <p className="mt-2 text-sm text-red-500">{errors.harptosDate}</p>}
              </div>
            </div>

            <div>
              <label htmlFor="campaignImage" className="block text-sm font-medium text-white">
                Imagem da Campainha
              </label>
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-600 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  {campaignImage ? (
                    <img src={campaignImage} alt="Campaign" className="mx-auto h-32 w-32 object-cover rounded-md" />
                  ) : (
                    <FaUpload className="mx-auto h-12 w-12 text-gray-400" />
                  )}
                  <div className="flex text-sm text-gray-300">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer bg-gray-700 rounded-md font-medium text-indigo-400 hover:text-indigo-300 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                    >
                      <span>Selecione um arquivo</span>
                      <input id="file-upload" name="file-upload" type="file" className="sr-only" onChange={handleImageUpload} accept="image/*" />
                    </label>
                    <p className="pl-1">ou arraste e solte</p>
                  </div>
                  <p className="text-xs text-gray-400">PNG, JPG, GIF até 10MB</p>
                </div>
              </div>
              {errors.campaignImage && <p className="mt-2 text-sm text-red-500">{errors.campaignImage}</p>}
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 bg-[#7289da] text-white px-4 py-2 rounded hover:bg-[#677bc4]"
              >
                Criar a Campanha
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddCampanha;
