import React, { useState } from "react";
import { FaTimes,FaEdit, FaTrashAlt, FaCheck } from "react-icons/fa";

const Banco = ({ isMobile }) => {
  const [transactions, setTransactions] = useState([
    { id: 1, date: new Date("2023-05-01"), type: "Deposito", amount: 100, description: "Quest Reward" },
    { id: 2, date: new Date("2023-05-02"), type: "Retirada", amount: -50, description: "Compra de Equipmento" },
    { id: 3, date: new Date("2023-05-03"), type: "Deposito", amount: 200, description: "Tesouro Encontrado" },
    { id: 4, date: new Date("2023-05-04"), type: "Retirada", amount: -75, description: "Custo da taverna" },
    { id: 5, date: new Date("2023-05-05"), type: "Deposito", amount: 150, description: "Venda de minérios" },
  ]);

  const [editingId, setEditingId] = useState(null);
  const [editedTransaction, setEditedTransaction] = useState({});
  const [isDepositPopupOpen, setIsDepositPopupOpen] = useState(false);
  const [depositAmount, setDepositAmount] = useState("");
  const [depositDescription, setDepositDescription] = useState("");
  const [depositDate, setDepositDate] = useState("");

  const handleEdit = (transaction) => {
    setEditingId(transaction.id);
    setEditedTransaction({ ...transaction });
  };

  const handleSave = () => {
    setTransactions(transactions.map(t => t.id === editingId ? editedTransaction : t));
    setEditingId(null);
  };

  const handleCancel = () => {
    setEditingId(null);
  };

  const handleDelete = (id) => {
    setTransactions(transactions.filter(t => t.id !== id));
  };

  const handleChange = (e, field) => {
    if (field === "date") {
      setEditedTransaction({ ...editedTransaction, [field]: new Date(e.target.value) });
    } else {
      setEditedTransaction({ ...editedTransaction, [field]: e.target.value });
    }
  };

  const handleDepositClick = () => {
    setIsDepositPopupOpen(true);
    setDepositDate(new Date().toISOString().split('T')[0]);
  };

  const handleDepositSubmit = (e) => {
    e.preventDefault();
    const amount = parseFloat(depositAmount);
    if (amount !== 0) {
      const newTransaction = {
        id: transactions.length + 1,
        date: new Date(depositDate),
        type: amount > 0 ? "Deposito" : "Retirada",
        amount: amount,
        description: depositDescription || (amount > 0 ? "Depósito de Gold" : "Retirada de Gold")
      };
      setTransactions([...transactions, newTransaction]);
      setDepositAmount("");
      setDepositDescription("");
      setDepositDate("");
      setIsDepositPopupOpen(false);
    }
  };

  // Calcular o total
  const totalAmount = transactions.reduce((total, transaction) => total + transaction.amount, 0);

  return (
    <div>
      <h2 className="text-2xl font-bold mb-0">Banco do Grupo</h2>
      <div className="overflow-x-auto">
        <div className={`grid ${isMobile ? "grid-cols-1" : "grid-cols-2"} gap-4 mb-4`}>
        </div>
        <div className="mb-4">
        <table className="w-full bg-[#40444b] rounded overflow-hidden">
  <thead>
    <tr className="bg-[#2f3136] text-gray-300 uppercase text-sm leading-normal">
      <th className="py-3 px-2 text-left">DATA</th>
      <th className="py-3 px-2 text-left">VALOR</th>
      {!isMobile && <th className="py-3 px-2 text-left">DESCRIÇÃO</th>}
      <th className="py-3 px-2 text-left">AÇÃO</th>
    </tr>
  </thead>
  <tbody className="text-gray-300 text-sm font-light">
    {transactions.map((transaction) => (
      <tr key={transaction.id} className="border-b border-[#2f3136] hover:bg-[#36393f]">
        <td className="py-3 px-2 text-left whitespace-nowrap">
          {editingId === transaction.id ? (
            <input
              type="date"
              value={editedTransaction.date.toISOString().split('T')[0]}
              onChange={(e) => handleChange(e, "date")}
              className="bg-[#2f3136] text-gray-300 px-2 py-1 rounded"
            />
          ) : (
            transaction.date.toLocaleDateString()
          )}
        </td>
        <td className={`py-3 px-2 text-left ${transaction.amount >= 0 ? "text-green-400" : "text-red-400"}`}>
          {editingId === transaction.id ? (
            <input
              type="number"
              value={editedTransaction.amount}
              onChange={(e) => handleChange(e, "amount")}
              className="bg-[#2f3136] text-gray-300 px-2 py-1 rounded w-24"
            />
          ) : (
            <>{transaction.amount >= 0 ? "+" : ""}{transaction.amount.toLocaleString()} Gold</>
          )}
        </td>
        {!isMobile && (
          <td className="py-3 px-2 text-left">
            {editingId === transaction.id ? (
              <input
                type="text"
                value={editedTransaction.description}
                onChange={(e) => handleChange(e, "description")}
                className="bg-[#2f3136] text-gray-300 px-2 py-1 rounded w-full"
              />
            ) : (
              transaction.description
            )}
          </td>
        )}
        <td className="py-3 px-2 text-left">
          {editingId === transaction.id ? (
            <>
              <button onClick={handleSave} className="text-green-500 hover:text-green-700 mr-2">
                <FaCheck />
              </button>
              <button onClick={handleCancel} className="text-red-500 hover:text-red-700">
                <FaTimes />
              </button>
            </>
          ) : (
            <>
              <button onClick={() => handleEdit(transaction)} className="text-[#7289da] hover:text-[#677bc4] mr-2">
                <FaEdit />
              </button>
              <button onClick={() => handleDelete(transaction.id)} className="text-red-500 hover:text-red-700">
                <FaTrashAlt />
              </button>
            </>
          )}
        </td>
      </tr>
    ))}
    <tr className="border-b border-[#242529] bg-[#242529]">
      <td className="text-gray-300 py-3 px-2 text-left font-bold">TOTAL</td>
      <td className={`py-3 px-2 text-left font-bold ${totalAmount >= 0 ? "text-green-400" : "text-red-400"}`}>
        {totalAmount >= 0 ? "+" : ""}{totalAmount.toLocaleString()} Gold
      </td>
      {!isMobile && <td className="text-gray-300 py-3 px-2 text-left font-bold"></td>}
      <td className="text-gray-300 py-3 px-2 text-left font-bold"></td>
    </tr>
  </tbody>
</table>
        </div>
        <div className={`flex ${isMobile ? "flex-col" : "space-x-6"}`}>
          <button
            className={`bg-[#43b581] text-white px-4 py-2 rounded hover:bg-[#3ca374] ${isMobile ? "mb-2 w-full" : ""}`}
            onClick={handleDepositClick}
          >
            Depositar/Retirar Gold
          </button>
        </div>
      </div>

      {isDepositPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-[#36393f] p-6 rounded-lg">
            <h3 className="text-xl font-bold mb-4">Depositar/Retirar Gold</h3>
            <form onSubmit={handleDepositSubmit}>
              <input
                type="date"
                value={depositDate}
                onChange={(e) => setDepositDate(e.target.value)}
                className="w-full bg-[#40444b] text-white px-3 py-2 rounded mb-4"
                required
              />
              <input
                type="number"
                value={depositAmount}
                onChange={(e) => setDepositAmount(e.target.value)}
                placeholder="Quantidade de Gold (+ ou -)"
                className="w-full bg-[#40444b] text-white px-3 py-2 rounded mb-4"
                required
              />
              <input
                type="text"
                value={depositDescription}
                onChange={(e) => setDepositDescription(e.target.value)}
                placeholder="Descrição (opcional)"
                className="w-full bg-[#40444b] text-white px-3 py-2 rounded mb-4"
              />
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setIsDepositPopupOpen(false)}
                  className="bg-[#747f8d] text-white px-4 py-2 rounded mr-2 hover:bg-[#68727f]"
                >
                  Cancelar
                </button>
                <button
                  type="submit"
                  className="bg-[#43b581] text-white px-4 py-2 rounded hover:bg-[#3ca374]"
                >
                  Confirmar
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Banco;