import React from 'react';

const Perfil = () => {
    return (
      <div>
        <h2 className="text-2xl font-bold mb-4">Perfil</h2>
        <div className="bg-[#40444b] p-6 rounded shadow">
          <div className="flex items-center mb-6">
            <img
              src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              alt="User profile"
              className="w-24 h-24 rounded-full mr-6"/>
            <div>
              <h3 className="text-2xl font-semibold">John Doe</h3>
              <p className="text-gray-300">Game Master</p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h4 className="text-lg font-semibold mb-2">Informações Pessoais</h4>
              <p className="text-gray-300">Email: john.doe@example.com</p>
              <p className="text-gray-300">Localização: Indaial, SC</p>
              <p className="text-gray-300">Membro desde: 24/02/2024</p>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-2">Resumo</h4>
              <p className="text-gray-300">Campainhas Criadas: 5</p>
              <p className="text-gray-300">Personagens Criados: 12</p>
              <p className="text-gray-300">Total Sessões: 50</p>
            </div>
          </div>
          <div className="mt-6">
            <h4 className="text-lg font-semibold mb-2">Bio</h4>
            <p className="text-gray-300">
             GM experiente com paixão por criar mundos de fantasia envolventes e contar histórias épicas. Sempre em busca de novos jogadores para participar de minhas campanhas!
            </p>
          </div>
          <button className="mt-6 bg-[#7289da] text-white px-4 py-2 rounded hover:bg-[#677bc4]">
            Editar Perfil
          </button>
        </div>
      </div>
    );
  };

  export default Perfil;