import React from 'react';

const Campanha = () => {
  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Campanhas Ativas</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {[1, 2, 3].map((campaign) => (
          <div key={campaign} className="bg-[#40444b] p-4 rounded shadow">
            <h3 className="text-xl font-semibold mb-2">Campanha {campaign}</h3>
            <p className="text-gray-300 mb-2">Descrição da Campanha {campaign}</p>
            <button className="bg-[#7289da] text-white px-4 py-2 rounded hover:bg-[#677bc4]">
              Ver detalhes
            </button>
          </div>
        ))}
      </div>
      <button className="mt-4 bg-[#43b581] text-white px-4 py-2 rounded hover:bg-[#3ca374]">
        Nova Campanha
      </button>
    </div>
  );
};
export default Campanha;